<template>
  <v-container class="my-6 py-6">
    <sucursales-location ref="sucursalesMap"></sucursales-location>
    <div
      v-for="distribuidor in distribuidores"
      :key="distribuidor.id"
      class="py-4"
    >
      <h2 class="my-4">
        Distribuidor: {{ distribuidor.nombre_distribuidor }}
      </h2>
      <v-row
        justify="center"
      >
        <v-card
          v-for="sucursal in sucursalesPorDistribuidor(distribuidor)"
          :value="sucursal"
          :key="sucursal.id"
          max-width="250"
          width="250"
          class="mx-4 my-4"
          :style="sucursalId === sucursal.id ?
             'border: 2px solid #FF4C00;' :
             ''"
        >
          <v-card-text>
            <div>Sucursal:</div>
            <p
              class="display-1 text--primary"
            >
              {{ sucursal.nombre_sucursal }}
            </p>
            <div class="text-primary">
              Calle: {{ sucursal.direccion.calle }}<br>
              Exterior: {{ sucursal.direccion.numero_exterior }} / Interior: {{ sucursal.direccion.numero_interior }} <br>

              Colonia: {{ sucursal.direccion.colonia }}<br>
              {{ sucursal.direccion.ciudad }} - {{ sucursal.direccion.estado }}<br>
              CP: {{ sucursal.direccion.codigo_postal }}<br>
              Contacto: {{ sucursal.direccion.numero_contacto }}
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              @click="selectSucursal(sucursal)"
            >
              Seleccionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { axiosBase } from '@/api_utils/axios-base'
import SucursalesLocation from '@/components/utils/Maps/SucursalesLocation'
import { mapActions, mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'Directorio de tiendas'
  },
  data() {
    return {
      distribuidores: [],
      sucursales: [],
      sucursalesToMap: [], 
    }
  }, 
  components: {
    SucursalesLocation  
  
  },
  created() {
    this.getAllData()
  },
  computed: {
    ...mapState('products', ['sucursalId', 'sucursals'])
  },
  methods: {
    ...mapActions('products', ['fetchProducts', 'setDistributorId', 'setSucursalId', 'getSucursals', 'getFeaturedProducts']),
    ...mapActions('usuario', ['setCustomerDiscount']),
    async getAllData() {
      await Promise.all([
        this.traerDistribuidores()
      ])
        .then(async response => {
          this.distribuidores = response[0].data
          if (this.sucursals) {
            this.sucursales = this.sucursals
          } else {
            await this.getSucursals()
            this.sucursales = this.sucursals
          }
          
          this.sendSucursalesPorDistribuidor()
        })
    },
    traerDistribuidores() {
      return axiosBase.get('/personas/api/distribuidor/')
    },
    traerSucursales() {
      return axiosBase.get('/personas/api/sucursal/')
    },
    //si ya no se necesita listar con cards los distris con sus sucursales borrar la función:
   //y todo lo del template para que solo quede el mapa.
    sucursalesPorDistribuidor(distribuidor) {
      return this.sucursales.filter(sucursal => {
        return sucursal.distribuidor.id === distribuidor.id
      })
    },
    sendSucursalesPorDistribuidor() {
      this.distribuidores.forEach(distribuidor => {
        let sucursalesDeDistri= {
          distribuidor: distribuidor.nombre_distribuidor
        }
        let sucursalesDistri = this.sucursales.filter(sucursal => {
          return sucursal.distribuidor.id === distribuidor.id
        })
        sucursalesDeDistri.sucursales = sucursalesDistri
        this.sucursalesToMap.push(sucursalesDeDistri)
      })
      this.$refs.sucursalesMap.initMap(this.sucursalesToMap)
    },
    selectSucursal(sucursal) {
      this.setDistributorId(sucursal.distribuidor.id)
      this.setSucursalId(sucursal.id)
      this.setCustomerDiscount(sucursal.distribuidor.id)
      this.getFeaturedProducts()
      this.fetchProducts()
      this.$router.replace({name: 'Construfácil'})
    }
  }
}
</script>
