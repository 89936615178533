<template>
  <div>
    <h1 
      style="text-align:center;
      font-size: 1.3cm; 
      color: #2D4761;
      margin:15px"
    >
      Aviso de privacidad
    </h1>
    <h2 
      style="text-align:center;
      font-size: 0.8cm; color: #2D4761;"
    >
      https://construfácil.com.mx
    </h2>
    <v-row 
      class="py-6 my-6" 
      justify="center" 
      color="secondary" 
      no-gutters
    >
      <v-col 
        cols="8" 
        class="text-center"
      >
        <p 
          style=
          "text-align:justify; 
          left: 500px; color: black; 
          font-family: Arial, Helvetica, sans-serif;
          line-height : 25px;
        ">
          A efecto de garantizar la privacidad y el derecho a la
          autodeterminación informativa de las personas, de conformidad con lo
          dispuesto en la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (la “Ley), su Reglamento, los
          Lineamientos del Aviso de Privacidad y las Políticas y Procedimientos,
          T A 2000 S.A. de C.V. (“TYASA”), con domicilio con Carretera Federal
          México-Veracruz km. 321, interior 2, sin número, colonia Escamela,
          código postal 94450, Ixtaczoquitlán, Veracruz, México, es el
          responsable del uso legítimo, controlado e informado de sus datos
          personales, y al respecto le informamos lo siguiente: 
          <br>
          <br>
          En términos de
          la Ley, al proporcionar sus datos personales a TYASA, a excepción de
          que manifieste lo contrario, usted consiente tácitamente el
          tratamiento de los mismos de conformidad con lo establecido en este
          Aviso de Privacidad. Los datos personales que recabamos de usted, los
          utilizaremos para las siguientes finalidades que son necesarias para
          cumplir con su solicitud: (i) verificar y confirmar su identidad como
          visitante, cliente o proveedor; (ii)revisar su situación patrimonial;
          (iii) administrar y operar los servicios que solicita o contrata con
          nosotros; (iv) alta y seguimiento de operaciones comerciales; (v)
          mercadotecnia, publicidad así como proporcionar información respecto
          de los bienes que comercializamos o servicios que prestamos; (vi)
          informar sobre nuevos productos o servicios o cambios en los mismos;
          (vii) evaluar la calidad de nuestros productos o servicios,
          comunicación interna, la realización de encuestas, así como la
          creación e implementación de procesos analíticos y estadísticos
          necesarios o convenientes para dichos efectos; (viii) atender
          requerimientos legales de autoridades competentes; (ix) aquellos
          relacionados con la selección, reclutamiento y contratación de
          personal; y (x) realizar cualquier actividad complementaria, auxiliar,
          compatible con las finalidades anteriores, sin que para ello se
          requiera obtener nuevamente su consentimiento. 
          <br>
          En caso de que no desee
          que sus datos personales sean tratados para los fines descritos, desde
          este momento usted nos puede comunicar lo anterior, manifestando su
          negativa dentro de los siguientes cinco (5) días antes de que sus
          datos sean tratados.
          <br>
          <br>
          Para llevar a cabo las finalidades descritas en
          el presente Aviso de Privacidad, TYASA podrá recabar y, en su caso,
          tratar los datos personales que a continuación se especifican: nombre
          completo; copia de identificación oficial con fotografía (pasaporte,
          credencial de elector, cédula profesional, licencia); edad; fecha de
          nacimiento, copia de acta de nacimiento, domicilio, copia de
          comprobante de domicilio (recibos de luz, teléfono, agua o predial),
          copia de precartilla o cartilla militar liberada, estado civil; datos
          académicos, copia del acta de matrimonio, copia de documento
          migratorio, correo electrónico, teléfono particular, del trabajo,
          particular, celular, copia del registro federal de contribuyentes
          (RFC), copia de la clave única de registro poblacional (CURP).
          <br>
          Asimismo, TYASA también podrá recabar datos personales sensibles como:
          Estado de salud para efecto prevención y diagnóstico médico,
          prestación de asistencia sanitaria, así como para atender los
          requerimientos de las autoridades de salud pública. 
          <br>
          <br>
          Dichos datos
          personales pueden ser obtenidos directamente de usted, ya sea
          personalmente o a través de cualquier medio electrónico, óptico,
          sonoro, visual o a través de cualquier otra tecnología, así como a
          través de terceros y de otras fuentes permitidas por las disposiciones
          legales aplicables, seguridad personal y laboral en nuestras
          instalaciones, así como por los bienes y/o productos adquiridos,
          servicios solicitados o contratados por usted con TYASA. 
          <br>
          <br>
          TYASA, cuenta
          con las medidas de seguridad, administrativas, técnicas y físicas
          necesarias para salvaguardar la privacidad y confidencialidad de sus
          datos personales, así como para cumplir con los principios de
          protección de datos personales previstos en la Ley. 
          <br>
          <br>
          Sistema de
          Video-Vigilancia. Sus datos personales serán utilizados con la
          finalidad de contar con un control de entradas y salidas y procurar la
          seguridad de las personas dentro y fuera de las instalaciones de
          TYASA. 
          <br>
          <br>
          De conformidad con el artículo 36 de la Ley, le informamos que
          TYASA podrá realizar la transferencia de sus datos personales, entre
          otros, a sus empresas afiliadas, autoridades y a terceros prestadores
          de los servicios necesarios para cumplir con su solicitud, operación y
          para el cumplimiento de las finalidades previstas en el presente Aviso
          de Privacidad, por lo que salvo que manifieste su oposición, se
          entenderá que otorga su consentimiento a TYASA para transferir a
          terceros sus datos personales, siempre que ello sea necesario para el
          cumplimiento de las finalidades establecidas en el presente Aviso de
          Privacidad. 
          <br>
          <br>
          Usted tiene derecho a conocer qué datos personales tenemos
          de usted, para qué los utilizamos y las condiciones del uso que les
          damos (Acceso). Asimismo, es su derecho solicitar la corrección de su
          información personal en caso de que esté desactualizada, sea inexacta
          o incompleta (Rectificación); es su derecho que la eliminemos de
          nuestros registros o bases de datos cuando considere que la misma no
          está siendo utilizada conforme a los principios, deberes y
          obligaciones previstas en la normativa (Cancelación); así como
          oponerse al uso de sus datos personales para fines específicos
          (Oposición). Estos derechos se conocen como derechos ARCO. 
          <br>
          <br>
          Para el
          ejercicio de cualquiera de los derechos ARCO, usted deberá presentar
          la solicitud respectiva a través de la dirección de correo electrónico
          que se indica a continuación. 
          <br>
          <br>
          Para conocer el procedimiento y
          requisitos para el ejercicio de los derechos ARCO, usted podrá llamar
          al siguiente número telefónico +52 (272) 724 4700, ext. 335 y 360;
          ingresar a nuestro sitio de Internet www.tyasa.com a la sección Aviso
          de Privacidad, o bien ponerse en contacto con el departamento legal al
          teléfono +52 (272) 724 4700, ext. 335 y 360 juridico@ta42.com, quien
          dará trámite a las solicitudes para el ejercicio de estos derechos, y
          atenderá cualquier duda que pudiera tener respecto al tratamiento de
          su información. El presente Aviso de Privacidad puede sufrir
          modificaciones, cambios o actualizaciones derivadas de nuevos
          requerimientos legales; de nuestras propias necesidades por los
          productos o servicios que ofrecemos; de nuestras prácticas de
          privacidad; de cambios en nuestro modelo de negocio, o por otras
          causas. 
          <br>
          <br>
          La solicitud deberá contener y acompañarse de lo siguiente:
          (i) su nombre y domicilio o cualquier otro medio para comunicarle
          nuestra respuesta; (ii) los documentos que acrediten su identidad,
          personalidad jurídica y/o la de su representante legal; (iii) la
          descripción clara y precisa de los datos personales respecto de los
          cuales se busca ejercer alguno de los derechos mencionados; (iv)
          cualquier otro documento o elemento que facilite la localización de
          sus datos personales y; (v) tratándose de solicitudes de
          rectificación, deberá indicar además las modificaciones a realizarse y
          aportar la documentación que sustente su petición, en caso de que ésta
          sea procedente. 
          <br>
          <br>
          Usted puede revocar el consentimiento que, en su caso,
          nos haya otorgado para el tratamiento de sus datos personales. Sin
          embargo, es importante que tenga en cuenta que no en todos los casos
          podremos atender su solicitud o concluir el uso de forma inmediata, ya
          que es posible que por alguna obligación legal requiramos seguir
          tratando sus datos personales. Asimismo, usted deberá considerar que
          para ciertos fines, la revocación de su consentimiento implicará que
          no le podamos seguir prestando el servicio que nos solicitó, o la
          conclusión de su relación con nosotros. 
          <br>
          <br>
          Para conocer el procedimiento,
          requisitos para la revocación del consentimiento, y revocar el mismo
          usted podrá ingresar a nuestro sitio de Internet www.tyasa.com a la
          sección Aviso de Privacidad, llamar al siguiente número telefónico +52
          (272) 724 4700 ext. 335 y 360, o bien ponerse en contacto con nuestro
          Departamento Legal a través de la dirección juridico@ta42.com.
          <br>
          <br>
          Nos
          comprometemos a mantenerlo informado sobre los cambios que pueda
          sufrir el presente Aviso de Privacidad, a través de nuestro sitio de
          internet. El procedimiento a través del cual se llevarán a cabo las
          notificaciones sobre cambios o actualizaciones al presente Aviso de
          Privacidad se llevará de conformidad con las políticas y
          procedimientos internos de TYASA. 
          <br>
          <br>
          De conformidad con lo previsto en la
          Ley, su Reglamento, los Lineamientos del Aviso de Privacidad y las
          Políticas y Procedimientos de TYASA: (a) reconoce que ha leído y
          entiende los alcances del Aviso de Privacidad de TYASA y (b) autoriza
          a TYASA a recabar, tratar y transferir sus datos personales para los
          fines establecidos en y de conformidad con lo previsto en el presente
          Aviso de Privacidad, según el mismo sea modificado de tiempo en
          tiempo.
        </p>
        <span 
          style="float: right; 
          font-family: Arial, Helvetica, sans-serif;"
        > 
          <span 
            style="color:#2D4761; 
            font-weight: bold; 
            font-family: Arial, Helvetica, sans-serif;"
          >
            Fecha de Actualización: 
          </span>
            Diciembre 2020
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Aviso de privacidad'
  },
};
</script>

<style></style>
