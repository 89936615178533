<template>
  <div>
    <h2>Geolocation</h2>
    <div id="map"></div>
  </div>  
</template>

<script>
import { mapState } from 'vuex'
import { addressesMixin } from '@/mixins/addresses.js'

export default {
  mixins: [addressesMixin],
  computed: {
    ...mapState(['mapsLoader']),
  },
  methods: {
    initMap(sucursales) {
      if (!window.google) {
        this.mapsLoader.load().then(() => {
          this.displayMarkers(sucursales)
        })
      } else {
        this.displayMarkers(sucursales)
      }
    },
    displayMarkers(sucursales) {
      const mapOptions = {
        zoom: 10,
        // poner una var aqui si el usuario activa la opción de geolocation al principio:
        // const myLatLng = { lat: 23213, lng: -2312.23}
        center: { lat:19.0123594, lng:-98.21670429999999 }
      }
      let geocoder = new google.maps.Geocoder();
      let map = new google.maps.Map(document.getElementById('map'), mapOptions)
      let lats = []
      let lngs = []

      sucursales.forEach(distribuidor => {
        let randomColor = "#" + ((1<<24)*Math.random() | 0).toString(16) 
        distribuidor.sucursales.forEach(async (sucursal) => {
          let sucursalAddress = this.addressToString(sucursal.direccion)
            await geocoder.geocode( { 'address': sucursalAddress }, function(results, status) {
            const location = results[0].geometry.location
            lats.push(location.lat())
            lngs.push(location.lng())
            let marker = new google.maps.Marker({
              position: location,
              map: map,
              title: distribuidor.distribuidor,
              icon: 
              {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 10.5,
                fillColor: randomColor,
                fillOpacity: 1,
                strokeWeight: 0.4,
              },
            })
            marker.addListener("click", () => {
              infowindow.open(map, marker)
            })

            const contentString = `
              <div id="content">
                <div id="siteNotice">
                  <div>${distribuidor.distribuidor}</div>
                </div>
                <h1 id="firstHeading" class="firstHeading">${sucursal.nombre_sucursal}</h1><br>
                <div id="bodyContent">
                  <div>Calle: ${sucursal.direccion.calle}</div>
                  <div>Código Postal: ${sucursal.direccion.codigo_postal}</div>
                  <div>Número Exterior: ${sucursal.direccion.numero_exterior}</div>
                  <div>Número de contacto: ${sucursal.direccion.numero_contacto}</div>
                  <div></div>
                </div>
              </div>
            `
            const infowindow = new google.maps.InfoWindow({
              content: contentString,
            })
          })
          map.setCenter(this.calculateCenter(lats, lngs))
        })
      })
    },
    calculateCenter(lats, lngs) {
      const size = lats.length
      const sumLats = lats.reduce(this.sumCoordinates, 0)
      const sumLngs = lngs.reduce(this.sumCoordinates, 0)

      const latCenter = length > 0 ? sumLats / size : 0
      const lngCenter = length > 0 ? sumLngs / size : 0

      return {lat: latCenter, lng: lngCenter}
    },
    sumCoordinates(previousValue, currentValue) {
      return previousValue + currentValue
    }
  },
  created() {
    // this.initMap()
  }
}
</script>

<style scoped>
#map {
  height: 500px;
  width: 100%;
}
</style>